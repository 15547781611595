<template>
    <div class="top-search-container">
        <div class="input-area">
            <i class="el-icon-search i"></i>
            <input type="text" v-model="value" @keyup.stop="keyup" placeholder="请输入关键字">
        </div>
        <search-result-container :value="value"></search-result-container>
    </div>
</template>

<script>
import SearchResultContainer from "@/components/common/SearchResultContainer.vue";
    export default {
        data:function (){
          return {
              value:'',
          }
        },
        components:{
          SearchResultContainer
        },
        methods:{
            keyup(e){
                if (e.keyCode!==13) return;
                console.log('search value',this.value);
            }
        }
    }
</script>
<style scoped lang="less">
.top-search-container {
    //padding-left: 50px;
    width: 100%;
    height: 100%;
    position: relative;

    .type-list, .input-area {
        height: 100%;

    }

    display: flex;
    align-items: center;

    .input-area {
        position: relative;
        height: 30px;
        width: 100%;
        .i{
            position: absolute;
            top:8px;
            left: 10px;
        }
        input {
            color: #333;
            border-radius: 20px;
            width: 100%;
            padding-left: 30px;
            background-color: #eee;
            height: 100%;
            display: inline-block;
            border: 1px solid #ccc;
        }
    }
}
</style>
